<!-- 供应商详情页 -->

<template>
  <div class="box">
    <div class="nav_top">
      <div class="top"><top-nav></top-nav></div>
      <div class="text">
        <div class="first">绿水青山就是金山银山</div>
      </div>
    </div>
    <div style="background-color: #f5f5f5;padding:20px 0;">
    <div class="content_c">
      <div class="container">
        <div class="blog-detail">
          <div class="text mb-30">
            <h6 class="detail-title">{{ item.username }}</h6>

            <div class="right_bom">
              <div>
                成交订单总金额：<span class="money">{{ item.amountSum }}</span
                >元
              </div>
              <div>成交订单数量：{{ item.orderNum }}件</div>
            </div>
            <p>{{ item.synopsis }}</p>
            <!-- <p v-html="detailsItem.contentEdit" class="rich"></p> -->
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav";
import bottom from "../components/bottom";
import { province } from "../api/api";

export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {
      item: {},
    };
  },
  beforeMount() {
    let _province = localStorage.getItem("province");
    if (!_province) {
      this.getprovince();
    }
  },
  mounted() {
    this.into();
  },

  methods: {
    //获取省市
    async getprovince() {
      let res = await province();
      if (res.ResultCode == 0) {
        console.log(res, "res");
        localStorage.setItem("province", JSON.stringify(res.Data));
      }
    },
    async into() {
      this.item = this.$route.query.item;
      // console.log(this.item, "itemitem");
    },
  },
};
</script>

<style lang="scss" scoped>
.rich {
  ::v-deep img {
    width: 800px !important;
    margin: 50px auto;
  }
  ::v-deep p {
    line-height: 3;
  }
  ::v-deep table.MsoTableGrid.layui-table {
    margin: auto;
  }
}
.box {
  width: 100%;
  box-sizing: border-box;
  .nav_top {
    width: 100%;
    height: 540px;
    background-image: url("../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    
    .text {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      font-size: 3.125rem;
      font-family: 'YanShiYouRanXiaoKai';
      position: relative;
      animation-name: example;
      animation-duration: 4s;
      animation-iteration-count: 1;
      animation-direction: alternate;

      // transition: bounce-in 3s;

      .first {
        font-size: 6.875rem;
        // font-weight: bold;
        margin-bottom: 3.125rem;
      }
    }

    .top {
      width: 100%;
      height: 100px;
      background-size: 100% 100px;
      background-repeat: no-repeat;
    }
    h1 {
      font-size: 66px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 140px;
    }
    h2 {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 51px;
    }
    .bom {
      margin: 0 250px;
      margin-top: 207px;
      display: flex;
      flex: 1;
      justify-content: space-between;
      .list_style {
        padding-right: 137px;
        .title {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba($color: #ffffff, $alpha: 0.8);
        }
        .title2 {
          font-size: 34px;
          font-family: DIN Alternate;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          margin-top: 25px;
        }
      }
    }
  }
  .content_c {
    width: 65%;
    margin: 0 auto;
    border: 1px solid #e5e5e5;
    padding: 50px;
  background-color: #ffffff;
    // 新闻列表

    .blog-detail .detail-title {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      margin: 20px auto;
    }
    .blog-detail .text {
      width: 100%;
    }
    .blog-detail .text .time {
      text-align: center;
      margin: 20px auto;
    }
    p.rich {
      margin: auto;
      text-align: center;
    }
    .blog-detail {
      height: 100%;
      padding: 50px auto;
      margin: 20px 0;
      overflow: hidden;
    }
    p {
      text-indent: 30px;
      line-height: 3;
      padding:30px 0;
    }
    .right_bom {
      display: flex;
      justify-content: flex-end;
      div {
        &:first-child {
            padding: 0 20px;
          .money {
            color: #ff6000;
          }
        }
      }
    }
  }
  .content5 {
    width: 100%;
    height: 310px;
    background: #f5f5f5;
  }
}
</style>
